import React,{useState,useEffect} from 'react'
import {Button,CssBaseline,TextField,//Typography
  Container,CircularProgress  ,Box,Card,Typography, IconButton,Grid, Tooltip,
  MenuItem,MenuList,Popper,Fade,Paper,ClickAwayListener,FormControlLabel,Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import {ErrorGeneral,llamadaApiCarga,llamaApiFormData2 } from '../funciones/ClienteAxios';
import qs from 'qs';
import axios from 'axios';
//import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify'; 
//import CloudUploadIcon from '@material-ui/icons/CloudUpload';
//import PhoneIcon from '@material-ui/icons/Phone';
import { authUser } from '../funciones/AuthUser';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';
import Lista from './Lista';
import {GeneralesContext} from '../context/GeneralesContext'
import {Headset, InsertDriveFile, VideoLibrary,PhotoLibrary} from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
  },
  avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
  },
  form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
  },
  submit: {
      margin: theme.spacing(3, 0, 2),
  },
}));
let arrFormato=[
  {tipo:"MEDIA-IMG",sizeMax:16,formato:"jpg, jpeg, gif, png, svg, webp, bmp"},
  {tipo:"MEDIA-DOC",sizeMax:100,formato:"zip, pdf"},
  {tipo:"MEDIA-VID",sizeMax:16,formato:"mp4, 3gp, mov"},
  {tipo:"MEDIA-AUD",sizeMax:16,formato:"mp3, aac, ogg"},
  ]

function ChatLista() {
  const classes = useStyles();
  let history = useHistory();
  const source = axios.CancelToken.source();    
  const {generales,guardarGenerales}=React.useContext(GeneralesContext);    
  let auxAccept1="application/pdf,image/jpg,image/jpeg,image/gif,image/png,image/svg,image/webp,image/bmp,"
  let auxAccept2=auxAccept1+"video/mp4,video/3gp,video/mov,audio/mp3,audio/aac,audio/ogg,.zip";
  let Dispvo =localStorage.getItem('Dispvo') ;    
  let auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
  let idusu= localStorage.getItem('UsuId');
  const [aviso, setAviso] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [espera,setEspera]= useState(false);
  const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
  const [contenido,setContenido]= useState(  { telefonos:'',textMensaje:'' } )
  const {telefonos,textMensaje}=contenido    
  const [listTel,setListTel]= useState(  [] )
  const [contador,setContador]= useState(  0 )
  const [file, setFile] = useState()
  const [url, setURL] = useState([])
  const [tipoMedia, setTipoMedia] = useState("")
  const [progresoEnvio, setProgresoEnvio] = useState(0);
  const [listaTodo,setListaTodo]= useState([]);
  //const [tamMedia, setTamMedia] = useState(0)
  const [nombreArchivo, setNombreArchivo] = useState("")
  const [open, setOpen] =  useState(false);
  const [difusion, setDifusion] = useState(false);
  const anchorRef = React.useRef(null);
  useEffect(() => {
    if (!authUser()) {
      localStorage.clear()
      history.push("/");      
    }else{
      guardarGenerales({...generales,Titulo:'Difusión redmorena.mx'})  
    }
  
  }, [])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
          anchorRef.current.focus();
      }

      prevOpen.current = open;
    }, [open]);

  const llenarListEvid = (cadena,fecha) => {   
    let auxSize =0   
    let auxFileName=tipoMedia==="MEDIA-DOC"?nombreArchivo.completo :" "
    let auxMensaje=tipoMedia==="MEDIA-DOC"?" ":(textMensaje.length===0?" ": textMensaje)
    let bodyFormData = new FormData();    
    bodyFormData.append('Origen',process.env.REACT_APP_TEL_ORI)
    bodyFormData.append('Usuario',idusu)
    bodyFormData.append('Contacto',cadena)
    bodyFormData.append('Difusion',difusion?1:0)
    bodyFormData.append('Mensaje',auxMensaje)
    bodyFormData.append('idDispositivo','wed4513s1c2s1cds1cd')
    bodyFormData.append('FileName',auxFileName)    
    bodyFormData.append('multi-files',file)    
    auxSize=file.size
    let url = "msg-send-file_1_1";
    function respuesta(auxiliar) {
      if (auxiliar[0]?.Archivos?.archivo!=="") 
      { console.log(auxiliar);
        setProgresoEnvio(0)
        let mensaje="Se envio correctamente el archivo"
        setTipoAdvertencia("success")      
        setMensaje(mensaje);
        setAviso(true)     
        toast.success(`${mensaje}`,{
          pauseOnFocusLoss: false, theme: "colored",
          toastId: `nvo-msg-arch${1}`
        })
        setListTel([])
        setContenido({...contenido,textMensaje:''})  
        borrarArchivo()                                                                     
      }       
    }    
    llamaApiFormData2(bodyFormData, url,respuesta,ErrorGeneral,setEspera,history,source,auxSize,setProgresoEnvio,tipoMedia,fecha);
  }  
  const handleCloseSN = () => {
    setMensaje('');
    setAviso(false);
  };

  const llamaApi = (cadena) => {     
    let UsuId =localStorage.getItem('UsuId') ;          
    let data = qs.stringify({
        Usuario:UsuId,
        Origen:process.env.REACT_APP_TEL_ORI,
        Contacto:cadena,
        Mensaje:textMensaje,
        Difusion:difusion?1:0,
        idDispositivo: auxDispV,
    });
    let url = "msg-send_1_1";
    function respuestaDuplicado(auxiliar){        
      
      if (auxiliar   ) 
      { console.log(auxiliar);
        let mensaje="Se envio correctamente el mensaje"
        toast.success(mensaje,{
          pauseOnFocusLoss: false,                    
          toastId: `nvo-perfil${1}`
        })
        setListTel([])
        setContenido({...contenido,textMensaje:''})
      } 
      else 
      {   //let mensaje = "Nombre ya registrado, ingrese un Nombre diferente.";
        let mensaje = auxiliar.mensaje//"Nombre ya registrado, ingrese un Nombre diferente.";
        toast.warning(mensaje,{
          pauseOnFocusLoss: false,                    
          toastId: `nvo-perfil${6}`
        })                                    
        mandaAlerta(mensaje, "warning");            
      }
  }
    llamadaApiCarga(data, url, respuestaDuplicado,ErrorGeneral,setEspera,history);
  };
  const mandaAlerta=(mensaje,tipoAdvert)=>
  {
    setMensaje(mensaje);
    setTipoAdvertencia(tipoAdvert)  
    setAviso(true);      
  }
  const onSubmit = e =>
  { e.preventDefault();    
    if (listTel.length!==0 )
    { let cadena=""
      //let snLada=""
      let cad2=""
      if (listTel.length<500) {
        listTel.forEach ((elemento)=>{ cadena=`${cadena}+521${elemento.tel},`})
        cad2=cadena.substr(0,cadena.length-1)  
      }else{
        let cortado=listTel.slice(0,500)
        cortado.forEach ((elemento)=>{ cadena=`${cadena}+521${elemento.tel},`})
        cad2=cadena.substr(0,cadena.length-1)  
      }
      
      if (file)
      {                  
        let today = new Date();                    
        let fec = moment(today);         
        llenarListEvid(cad2,fec.format("YYMMDD"))                                             
      }
      else
      { if (textMensaje.length>=4) 
        { llamaApi(cad2)    
        }             
        else
        { setAviso(true);
          let mensaje="Debe escribir un Mensaje mayor a 4 caracteres"
          mandaAlerta(mensaje, "warning"); 
        }                   
      }                                   
    }
    else
    {
      setAviso(true);
      //setMensaje("")
      let mensaje="Debe seleccionar un Teléfono"
      mandaAlerta(mensaje, "warning"); 
    }   
  }

  const onChange = e =>
	{  
    let expNopermitida = new RegExp('[%{}*|`]');        
    let expMenos = new RegExp("'");
    let expMas = new RegExp('"');             
    let expCadena = new RegExp('[A-Za-z0-9.$@#_&-+()/:;!?,<>=]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
        !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
    {
      setContenido({
          ...contenido, 
          [e.target.name] : e.target.value  
      })
      if (aviso) 
      {   setMensaje('');
          setAviso(false);
      }
    }		
	};

  const onChangeTel = e =>
	{  
    let expNopermitida = new RegExp('[A-Za-z.$@#_&-+()/:;!?<>=%{}*|`]');        
    let expMenos = new RegExp("'");
    let expMas = new RegExp('"');             
    let expCadena = new RegExp('[0-9,]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
        !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
    {
      setContenido({
          ...contenido, 
          [e.target.name] : e.target.value  
      })
      if (aviso) 
      {   setMensaje('');
          setAviso(false);
      }
    }		
	};

  const agregaTel=()=>{
    let aux=[]
    let aux2=listTel
    let auxmensaje=""
    if (telefonos.length>10) 
    { let cantidad = (telefonos.match(/,/g) ||[]).length;    
      let auxTelefonos=  telefonos
      let posicion=0
      for (let index = 0; index < cantidad; index++) 
      {
        let element = auxTelefonos.slice(0,auxTelefonos.indexOf(","))
        auxTelefonos=auxTelefonos.slice(auxTelefonos.indexOf(",")+1,auxTelefonos.length)
        posicion=posicion+1+auxTelefonos.indexOf(",")        
        let auxBusq=aux2.findIndex((telefono)=>telefono.tel===element)
        if (auxBusq === -1 ) {
          aux={id:contador+index,tel:element.trim(),valor:1}
          aux2.push(aux)  
        }
        else{
          auxmensaje=`Teléfono ${element} ya seleccionado, escribe un número diferente`
          toast.warning(auxmensaje ,{
            pauseOnFocusLoss: false, theme: "colored",
            toastId: `incorrecto-sel${index+2}`
          }); 
        }
      }
      if (auxTelefonos.length===10) 
      {
        let auxBusq=aux2.findIndex((telefono)=>telefono.tel===auxTelefonos)
        if (auxBusq === -1 ) {
          aux={id:contador+cantidad+1,tel:auxTelefonos.trim(),valor:1}
          aux2.push(aux)
          auxTelefonos=""
          cantidad=cantidad+1
        }
        else{
          auxmensaje=`Teléfono ${auxTelefonos} ya seleccionado, escribe un número diferente`
          toast.warning(auxmensaje ,{
            pauseOnFocusLoss: false, theme: "colored",
            toastId: `incorrecto-sel${0}`
          }); 
        }        
      }
      console.log(aux2);
      setListTel(aux2)
      setContador(contador+cantidad)
      setContenido({...contenido,telefonos:""})      
    }
    else
    {    
      let auxBusq=aux2.findIndex((telefono)=>telefono.tel===telefonos)
      if (auxBusq === -1 ) {
        aux={id:contador,tel:telefonos,valor:1}      
        aux2.push(aux)
        setListTel(aux2)
        setContador(contador+1)
        setContenido({...contenido,telefonos:""})
      }
      else{
        auxmensaje=`Teléfono ${telefonos} ya seleccionado, escribe un número diferente`
        toast.warning(auxmensaje ,{
          pauseOnFocusLoss: false, theme: "colored",
          toastId: `incorrecto-sel${1}`
        }); 
      }         
    }    
  }

  function borrarTel(numero){
    let aux2=listTel
    let pos =aux2.filter((elemento)=>elemento.id!==numero)        
    setListTel(pos)            
  }

  function borrarArchivo(){
    setURL([])
    setFile()
    setTipoMedia("")   
    setNombreArchivo("")
  }

  const onChangeArchivo=(event,valido)=>{
    setURL([])
    setFile()
    setTipoMedia("")
    setNombreArchivo("")    
    let auxSize =0
    let auxTipMedia=""
    const regex = /([a-zA-Z0-9\s_\.\-\(\):])+(.zip|.pdf|.jpg|.jpeg|.gif|.png|.svg|.webp|.bmp|.mp4|.3gp|.mov|.mp3|.aac|.ogg)$/
    //let regex2 = new RegExp("[$%@#&+(){}/:;!?,<>= ]", "g");        
    if(event.target.files[0])
    { let arch = event.target.files[0]
      let bandera=false
      arrFormato.forEach((elemento)=>{
        let auxpunto=arch.name.lastIndexOf(".")
        let tipo=arch.name.substring(auxpunto+1,arch.name.length)
        if (!bandera && elemento.formato.search(tipo)!==-1) {          
          bandera=true
          if (valido===1) 
          {
            auxTipMedia="MEDIA-DOC" 
            auxSize=100
          }
          else
          {
            auxTipMedia=elemento.tipo
            auxSize=elemento.sizeMax
          } 
        }          
      })
      if (arch.name.toLowerCase().match(regex) && arch.size < auxSize * 1024 * 1024) 
      {
        let nom ="";
        if(arch.name.length>=23)
        { nom =  arch.name.substring(0,22)+"..."+arch.name.substring(arch.name.lastIndexOf("."),arch.name.length);                        
        }
        else
        { nom = arch.name;
        }      
        let auxCode = uuidv4();
        let cadFormato=arch.name.slice(arch.name.lastIndexOf("."), arch.name.length);
        let auxNombre =auxCode+cadFormato        
        let myNewFile = new File([arch], auxNombre, {type: arch.type});    
        setNombreArchivo({corto:nom,completo:arch.name})
        toast.info(`Archivo seleccionado ${arch.name}`,{
          pauseOnFocusLoss: false, theme: "colored",
          toastId: `arc-sel${0}`
        });                
        setFile(myNewFile)
        //console.log(myNewFile);
        setURL(URL.createObjectURL(arch))
        setTipoMedia(auxTipMedia)        
      } 
      else 
      {
        let mensaje=`Formato de archivo incorrecto en ${arch.name}.`
        if (arch&& auxSize>0 && arch.size >= auxSize * 1024 * 1024) {
          mensaje = "El tamaño del archivo debe ser menor a "+auxSize+ "mb.";
        }
        toast.warning( mensaje,{
          pauseOnFocusLoss: false, theme: "colored",
          toastId: `arch-incorrecto-sel${0}`
        }); 
        setFile()
        setURL([])
      }            
    }
    else
    {
      toast.info("Seleccione archivo",{
        pauseOnFocusLoss: false, theme: "colored",
        toastId: `no-sel${0}`
      });
    }      
  }
  const handleImage = (event) => {
    //console.log("entrando imangen");
    onChangeArchivo(event,0)
  }
  const handleAudio = (event) => {
      onChangeArchivo(event,0)
  }
  const handleVideo = (event) => {
      onChangeArchivo(event,0)
  }
  const handleArchivo = (event) => {
      onChangeArchivo(event,1)
  }

  const opcionMenu=(llave,nomId,titulo,Icono)=>{
    return(
    <MenuItem key={llave} style={{alignItems:'center'}}>
      <label htmlFor={nomId}  >
        <Box display={"flex"} alignItems='center'>
        {Icono}
        <Box display={"flex"}  ml={1} style={{textAlign:'center'}}>
        <Typography  variant="inherit">
          {titulo}</Typography>
        </Box>
        </Box>        
      </label>
    </MenuItem>
    )
  }

  const metodoArchivo=()=>{
    return (
      <Grid item >
        {!file && listTel.length > 0 ? (
          <>
            <input
              accept={
                "image/jpg, image/jpeg, image/gif, image/png, image/svg, image/webp, image/bmp"
              }
              className={classes.input} id="tipo-imagen1" type="file" hidden={true}
              onChange={handleImage}
            />
            <input
              accept={auxAccept2} //accept={".zip, .pdf"} 
              className={classes.input} id="tipo-archivo1" type="file"
              hidden={true} onChange={handleArchivo}
            />
            <input
              accept={".mp4, .3gp, .mov"} className={classes.input}
              id="tipo-video1" type="file" hidden={true} onChange={handleVideo}
            />
            <input
              accept={".mp3, .aac, .ogg"} className={classes.input}
              id="tipo-audio1" type="file" hidden={true} onChange={handleAudio}
            />
          </>
        ) : null}
        {!file && listTel.length > 0 ?
          <span> 
          <IconButton
            ref={anchorRef} aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true" onClick={handleToggle} color="primary"
          >
            <AttachFileIcon />
          </IconButton>
          <Popper
            open={open} anchorEl={anchorRef.current} role={undefined}
            transition disablePortal style={{ zIndex: 2 }}
          >
            {({ TransitionProps, placement }) => (
              <Fade
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open} id="menu-list-grow"
                    onKeyDown={handleListKeyDown} style={{padding:0}}
                  >                    
                    {opcionMenu(1,"tipo-archivo1","Archivo",<InsertDriveFile color="primary" size="small"/>)}
                    {opcionMenu(3,"tipo-audio1","Audio",<Headset color="primary" />)}                        
                    {opcionMenu(0,"tipo-imagen1","Imagen",<PhotoLibrary color="primary" size="small"/>)}     
                    {opcionMenu(2,"tipo-video1","Video",<VideoLibrary color="primary" size="small"/>)}                                                                            
                  </MenuList>
                </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
          </span>
        : <Box mt={1}><AttachFileIcon color="disabled"/></Box> }
      </Grid>
    );
  }

  const addContactoNvl=(e)=>{
    e.preventDefault()
    //let aux2=listTel   
    let aux2=[]   
    if (listaTodo.length>1) {
      listaTodo.forEach((element,index) => {
        if (element.Cel) 
        {              
          let auxBusq=aux2.findIndex((telefono)=>telefono.tel===element.Cel)
          if (auxBusq === -1 ) 
          {
            let aux={id:(contador+index+1),tel:element.Cel,valor:1}      
            aux2.push(aux)                              
          }
          else
          {
            let auxmensaje=`Teléfono ${element.Cel} ya seleccionado, escribe un número diferente`
            toast.warning(auxmensaje ,{
              pauseOnFocusLoss: false, theme: "colored",
              toastId: `list-contacto-tel-error${index}`
            }); 
          }       
        }
      });    
    }
    else{
      if (listaTodo.Cel) 
        {              
          let auxBusq=aux2.findIndex((telefono)=>telefono.tel===listaTodo.Cel)
          if (auxBusq === -1 ) 
          {
            let aux={id:(contador+1),tel:listaTodo.Cel,valor:1}      
            aux2.push(aux)                              
          }
          else
          {
            let auxmensaje=`Teléfono ${listaTodo.Cel} ya seleccionado, escribe un número diferente`
            toast.warning(auxmensaje ,{
              pauseOnFocusLoss: false, theme: "colored",
              toastId: `list-contacto-tel-error${1}`
            }); 
          }       
        }
    }
    
    setListTel(aux2)                                   
    setContador(contador+1+listaTodo.length)
  }

  return (
  <div style={{width:45+'rem'}} >
    <Lista  listaTodo={listaTodo} setListaTodo={setListaTodo} />
    <Button size='small' variant={"outlined"} onClick={addContactoNvl}
      disabled={listaTodo.length===0 ?true:false} style={{marginTop:.5+'rem'}}>
      Asignar Contactos
    </Button>
    <CssBaseline />
    <div className={classes.paper}>
      { aviso && mensaje.length !== 0 ? 
        <Alert severity={tipoAdvertencia} onClose={handleCloseSN}> { mensaje } </Alert>
        :null
      }
      {listTel.length!==0?<Typography color='primary' variant="h5">Teléfonos Seleccionados {listTel.length}</Typography>:null}
      {listTel.length!==0?
      <Box id="texto" display={"flex"} maxWidth="xs" flexWrap="wrap" style={{ maxHeight:9+'rem', overflowY: "auto"}}>
        {listTel.map((elemento,index)=>{
          let auxColor=elemento.valor ? "primary":""
          return( 
          <Card  key={index} //onClick={()=>borrarTel( elemento.id)}
            style={{marginRight:.5+'rem',paddingLeft:.3+'rem',paddingRight:.3+'rem',
            marginTop:.3+'rem',marginBottom:.4+'rem'}}        
          >
            <Box display={"flex"} flexDirection="row" >          
              <Typography color={auxColor}> {elemento.tel} </Typography>
              {elemento.valor ?             
              <HighlightOffIcon size="small" style={{paddingBottom:.1+'rem',paddingTop:.1+'rem'}} 
                color={"error"} onClick={()=>borrarTel( elemento.id)} />                
              :null }
            </Box>
          </Card>)
        })                
        }
      </Box>      
      :null
      } 
      <form className={classes.form} onSubmit={onSubmit} noValidate >
        <Box display="flex" id="caja-tel" alignItems={"center"} flexDirection="row" style={{padding:0,margin:0}} >
          <Box display="flex" style={{padding:0,margin:0}} id="tel" >
            <TextField
              variant="outlined" margin="normal" required  id="tel-01" style={{margin:0}}
              label="Teléfono(s)" name="telefonos" type="text" value={telefonos}              
              onChange={onChangeTel} autoFocus size='small' //inputProps= {{ maxlength: 10 }}
            />
          </Box>
          <Box display="flex" style={{padding:0,margin:0}} >
            <Tooltip title={`Seleccionar Teléfono`}>
              <IconButton disabled={telefonos.length <10 || listTel.length===20? true:false}
                style={{margin:0}} color="primary" onClick={agregaTel}              
              >
                <AddIcCallIcon/>
              </IconButton>
            </Tooltip>
           
          </Box>
        </Box>
        <Alert size="small" severity="info" style={{margin:0,padding:0}}>Para seleccionar un archivo debe seleccionar un Teléfono</Alert>
        <Box display="flex" flexDirection="row" alignItems={'center'} >
          <Grid container style={{textAlign: 'center'}} direction="row">
            {nombreArchivo.corto ?
            <Box>
              <Card  style={{marginRight:.5+'rem',paddingLeft:.3+'rem',
                paddingRight:.3+'rem',marginTop:.3+'rem',marginBottom:.3+'rem'}}        
              >    
                <Box display={"flex"} flexDirection="row" >                   
                  <Typography color={"primary"}> {nombreArchivo.corto} </Typography>  
                  <HighlightOffIcon size="small" color={"primary"} onClick={()=>borrarArchivo()} 
                    style={{marginLeft:.5+'rem',paddingBottom:.1+'rem',paddingTop:.1+'rem'}}  />   
                 </Box>                          
              </Card>
              </Box>
            :null}            
            {metodoArchivo()}             
            {<Grid item xs={12}>
              {tipoMedia==="MEDIA-IMG" && url.length !== 0 ? <img src={url} style={{width: '20%', marginTop: 10}}/> : null}
            </Grid> }
          </Grid>          
        </Box>
        <FormControlLabel
          value={difusion}
          control={<Checkbox color="primary" />}
          label="Difusión" onChange={()=>setDifusion(!difusion)}
          labelPlacement="Difusión" 
        />
        <TextField
          variant="outlined" margin="normal" required fullWidth
          name="textMensaje" label="Mensaje"   id="caja-mensaje01"
          value={textMensaje} onChange={onChange} multiline
          rows={4} type="text"    
        />           
        <Box align="center">
          {!espera ?
            <Button type="submit" fullWidth
              variant="contained" color="primary"  className={classes.submit}
            >
              Enviar 
            </Button> 
            : <Box mt={2}> <CircularProgress /> </Box>
          }
        </Box>                       
      </form>
    </div>        
  </div>
  )
}

export default ChatLista